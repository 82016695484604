import React, {useEffect, useState} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import blogData from "../../Resources/BlogData";
import { motion } from "framer-motion";
import Blogs from "./Blogs";
import { BlogType } from "../../types/types";
import { fetchBlogBySlug } from "../../service/service";
import { format } from "date-fns";
import Markdown from "react-markdown";


const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [blog, setBlog] = useState<BlogType | null>(null)
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    const getBlog = async () => {
      try {
        const data = await fetchBlogBySlug(slug || "");
        setBlog(data.blogs as BlogType);
      } catch (error) {

        console.error("Error fetching blog:", error);
      } 
    };

    if (location.state !== null) {
      setBlog(location?.state?.blog as BlogType);
    } else {
      getBlog();
    }
  }, [slug, location, location.pathname]);

  if (!blog) {
    return (
      <div className="w-full pt-28 mt-10 flex justify-center items-center">
        Blog not found.
      </div>
    );
  }
  let url = "https://admin.techqilla.com";


  return (
    <>
      <div className="flex flex-col mx-auto max-w-screen-2xl items-center justify-center overflow-hidden">
        <div className="w-full pt-6 sm:pt-20 px-4 sm:px-6 lg:px-8 flex flex-col-reverse sm:flex-row justify-between sm:items-center gap-10">
          <div className="sm:w-[45%]">
            <motion.h1
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 200 }}
              className="text-3xl sm:text-5xl xl:text-6xl font-extrabold font-montserrat mb-4 sm:mb-6"
            >
              {blog.title}
            </motion.h1>
            <div className="flex justify-between items-center font-gtAmerica ">
              <div className="flex sm:justify-center items-center gap-4">
                <p className="sm:text-lg font-gtAmerica font-semibold">
                  {blog.techqilla_authors
                    .map((author) => author.name)
                    .join(", ")}
                </p>
              </div>
              <p className="sm:text-lg font-gtAmerica font-semibold">
                {format(blog.createdAt, "MM dd yyyy")}
              </p>
            </div>
          </div>
          <div className="sm:w-[55%]">
            <motion.img
              initial={{ scale: 0, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1 }}
              src={`${url}${blog.cover.url}`}
              alt="Blogimage"
              className="w-full"
            />
          </div>
        </div>
        <div className="py-4 sm:py-6 px-4 sm:px-6 lg:px-8 font-montserrat">
          <Markdown>{blog.content}</Markdown>
        </div>
        <Blogs />
      </div>
    </>
  );
};

export default BlogPost;
