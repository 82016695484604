import React from "react";
import CaseHero from "../commonCase/CaseHero";
import icon from "../../../../assets/svgs/softsidesvg.svg";
import Task from "../commonCase/Task";
import Task1 from "../commonCase/Task1";
import Design from "../commonCase/Design";
import website from "../../../../assets/images/softsideimg.webp";
import Work from "../commonCase/Work";
import work1 from "../../../../assets/images/softsidework1.webp";
import work2 from "../../../../assets/images/softsidework2.webp";
import work3 from "../../../../assets/images/softsidework3.webp";
import work4 from "../../../../assets/images/softsidework4.webp";
import insta from "../../../../assets/svgs/insta 2.svg";
import linkdin from "../../../../assets/svgs/linkdin 2.svg";
import youtube from "../../../../assets/svgs/youtube 2.svg";
import facebook from "../../../../assets/svgs/facebook 2.svg";
import Result from "../commonCase/Result";
import result1 from "../../../../assets/svgs/softsidecard1.svg";
import result2 from "../../../../assets/svgs/softsidecard2.svg";
import result3 from "../../../../assets/svgs/softsidecard3.svg";
import Data from "../../Data";
import OtherProject from "../commonCase/OtherProject";
import tanks from "../../../../assets/svgs/tankssvg.svg";
import aes from "../../../../assets/svgs/aessvg.svg";
import ggp from "../../../../assets/svgs/ggpsvg.svg";

const tableData = [
  { task: "Website Design & Dev", status: true },
  { task: "Social media", status: true },
  { task: "Lead generation", status: true },
];

const task1Data = [
  {
    subheading: "User Experience Analysis: ",
    des: "Locating any areas where the user experience is particularly challenging.",
  },
  {
    subheading: "Site Speed Assessment: ",
    des: "Checking loading times on both desktop and mobile.",
  },
  {
    subheading: "Mobile Optimization: ",
    des: "Making sure the site was fully optimized for mobile users.",
  },
  {
    subheading: "Product Page Review: ",
    des: "Assessing product page layouts, descriptions, and images.",
  },
  {
    subheading: "Checkout Process Review: ",
    des: "Go over the checkout process in order to find any steps that could be contributing to cart abandonment.",
  },
];

const task2Data = [
  {
    subheading: "User Experience Analysis: ",
    des: "Locating any areas where the user experience is particularly challenging.",
  },
  {
    subheading: "Site Speed Assessment: ",
    des: "Checking loading times on both desktop and mobile.",
  },
  {
    subheading: "Mobile Optimization: ",
    des: "Making sure the site was fully optimized for mobile users.",
  },
  {
    subheading: "Product Page Review: ",
    des: "Assessing product page layouts, descriptions, and images.",
  },
  {
    subheading: "Checkout Process Review: ",
    des: "Go over the checkout process in order to find any steps that could be contributing to cart abandonment.",
  },
];

const images = [work1, work2, work3, work4];

const linkData = [
  {
    logo: insta,
    link: "https://www.instagram.com/softsidebyhunt/",
    linkName: "Instagram.SoftSideByHunt",
  },
  {
    logo: youtube,
    link: "https://www.youtube.com/@softsidebyhunt",
    linkName: "Youtube.SoftSideByHunt",
  },
  {
    logo: linkdin,
    link: "https://www.linkedin.com/company/soft-side/posts/",
    linkName: "Linkdin.SoftSide",
  },
  {
    logo: facebook,
    link: "https://www.facebook.com/people/SoftSide/100095187117238/",
    linkName: "Facebook.SoftSide",
  },
];

const cardData = [
  {
    icon: result1,
    subheading: "Increased Conversion Rate",
    text: "After incorporating the ideas we suggested from our end, the store's conversion rates boosted by 25% in just three months.",
  },
  {
    icon: result2,
    subheading: "Reduced Drop-Offs",
    text: "With funnel analytics, 20% lower drop-off rates were also noted at strategic points in the sales funnel.",
  },
  {
    icon: result3,
    subheading: "Data-Driven Decisions",
    text: "The team was thus able to continue optimizing the site and make informed decisions with the aid of clear, useful insights from Google Data Studio reports.",
  },
];

const project = [
  {
    name: "TanskandCovers",
    img: tanks,
    link: "/case-study/tanksandcover",
  },
  {
    name: "AesRecruitment",
    img: aes,
    link: "/case-study/aes",
  },
  {
    name: "",
    img: ggp,
    link: "/case-study/ggp",
  },
];
const SoftSide = () => {
  return (
    <div>
      <CaseHero
        name="Softside"
        title="Case study for Softside"
        icon={icon}
        subtitle="Website designing, Branding, Social media marketing,
Lead generation"
        date={2024}
        className=""
      />
      <Task
        rows={tableData}
        bgColor="bg-[#2B5F71]" // Custom background color
        headerColor="bg-[#5C9F8E]" // Custom header color
        className=""
        text1="One of our clients, an online store, was experiencing high traffic but struggling with low conversion rates.
        <br/>
        <br/>
        They had a bathroom product called Bathtub Cushion and competitive prices but found it difficult to convert visitors into customers.
        <br/>
        <br/>
         Hence, they needed a solution to understand where potential customers were dropping off and to optimize their website for better conversion rates."
      />
      <Task1
        heading="Website design"
        text="We began with a thorough assessment of the website, which included:"
        list={task1Data}
        listcolor="text-[#2B5F71]"
        listStyleColor="fill-[#2B5F71]"
      />
      <Design
        heading="Website design"
        bgcolor="bg-[#2B5F71]"
        img={website}
        link="https://www.softsidebyhunt.com/"
        linkName="www.softsidebyhunt.com"
      />
      <Task1
        heading="Social media handeling"
        text="We worked in Instagram, Facebook and pinterest"
        list={task2Data}
        listcolor="text-[#2B5F71]"
        listStyleColor="fill-[#2B5F71]"
      />
      <Work
        bgColor="bg-[#2B5F71]"
        heading="Social media creatives"
        img={images}
        link={linkData}
        grid="lg:grid-cols-4"
      />
      <Result
        cardColor="bg-[#2B5F71]"
        heading="Results"
        text="The company achieved:"
        cards={cardData}
      />
      <OtherProject project={project} />
      <Data />
    </div>
  );
};

export default SoftSide;
