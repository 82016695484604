import React from "react";
import CaseHero from "../commonCase/CaseHero";
import icon from "../../../../assets/svgs/ggpsvg.svg";
import Task from "../commonCase/Task";
import Task1 from "../commonCase/Task1";
import website from "../../../../assets/images/ggpimg.webp";
import Design from "../commonCase/Design";
import work1 from "../../../../assets/images/ggpwork1.webp";
import work2 from "../../../../assets/images/ggpwork2.webp";
import Work from "../commonCase/Work";
import result1 from "../../../../assets/svgs/softsidecard1.svg";
import result2 from "../../../../assets/svgs/softsidecard2.svg";
import result3 from "../../../../assets/svgs/softsidecard3.svg";
import Result from "../commonCase/Result";
import linkdin from "../../../../assets/svgs/linkdin 2.svg";
import Data from "../../Data";
import tanks from "../../../../assets/svgs/tankssvg.svg";
import softside from "../../../../assets/svgs/softsidesvg.svg";
import aes from "../../../../assets/svgs/aessvg.svg";
import OtherProject from "../commonCase/OtherProject";

const tableData = [
  { task: "Logo Design", status: true },
  { task: "Website Design & Dev", status: true },
  { task: "Branding", status: true },
  { task: "Linkedin Marketing", status: true },
];

const task1Data = [
  {
    subheading: "User Experience Analysis: ",
    des: "Locating any areas where the user experience is particularly challenging.",
  },
  {
    subheading: "Site Speed Assessment: ",
    des: "Checking loading times on both desktop and mobile.",
  },
  {
    subheading: "Mobile Optimization: ",
    des: "Making sure the site was fully optimized for mobile users.",
  },
  {
    subheading: "Product Page Review: ",
    des: "Assessing product page layouts, descriptions, and images.",
  },
  {
    subheading: "Checkout Process Review: ",
    des: "Go over the checkout process in order to find any steps that could be contributing to cart abandonment.",
  },
];

const task2Data = [
  {
    subheading: "Linkdin Banner: ",
    des: "Locating any areas where the user experience is particularly challenging.",
  },
  {
    subheading: "Business Card: ",
    des: "Checking loading times on both desktop and mobile.",
  },
];

const images = [work1, work2];

const cardData = [
  {
    icon: result1,
    subheading: "Increased Conversion Rate",
    text: "After incorporating the ideas we suggested from our end, the store's conversion rates boosted by 25% in just three months.",
  },
  {
    icon: result2,
    subheading: "Reduced Drop-Offs",
    text: "With funnel analytics, 20% lower drop-off rates were also noted at strategic points in the sales funnel.",
  },
  {
    icon: result3,
    subheading: "Data-Driven Decisions",
    text: "The team was thus able to continue optimizing the site and make informed decisions with the aid of clear, useful insights from Google Data Studio reports.",
  },
];

const linkData = [
  {
    logo: linkdin,
    link: "https://www.linkedin.com/company/civilengineringrecruitment",
    linkName: "Linkdin.AesRecuriment",
  },
];
const project = [
  {
    name: "SoftSide",
    img: softside,
    link: "/case-study/softside",
  },
  {
    name: "TanskandCovers",
    img: tanks,
    link: "/case-study/tanksandcover",
  },
  {
    name: "AesRecruitment",
    img: aes,
    link: "/case-study/aes",
  },
];

const Ggp = () => {
  return (
    <div>
      <CaseHero
        name=""
        title="Case Study for Global Growth Partner"
        icon={icon}
        subtitle="Website designing and development, Branding"
        date={2024}
      />
      <Task
        rows={tableData}
        bgColor="bg-[#1E7B3C]" // Custom background color
        headerColor="bg-[#333333]" // Custom header color
        className=""
        text1="One of our clients, an online store, was experiencing high traffic but struggling with low conversion rates.
        <br/>
        <br/>
        They had recruitment services in engineering and competitive prices but found it difficult to convert visitors into customers.
        <br/>
        <br/>
         Hence, they needed a solution to understand where potential customers were dropping off and to optimize their website for better conversion rates."
      />
      <Task1
        heading="Website design"
        text="We began with a thorough assessment of the website, which included:"
        list={task1Data}
        listcolor="text-[#1E7B3C]"
        listStyleColor="fill-[#1E7B3C]"
      />
      <Design
        heading="Website design"
        bgcolor="bg-[#1E7B3C]"
        img={website}
        link="https://www.aesrecruitment.com"
        linkName="www.aesrecruitment.com"
      />
      <Task1
        heading="Branding"
        text="We designed their linkedin banner and Business cards"
        list={task2Data}
        listcolor="text-[#1E7B3C]"
        listStyleColor="fill-[#1E7B3C]"
      />
      <Work
        bgColor="bg-[#1E7B3C]"
        heading="Logo Design"
        img={images}
        link={linkData}
        grid="lg:grid-cols-2"
      />
      <Result
        cardColor="bg-[#333333]"
        heading="Results"
        text="The company achieved:"
        cards={cardData}
      />
      <OtherProject project={project} />
      <Data />
    </div>
  );
};

export default Ggp;
