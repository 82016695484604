import React from "react";
import arrow from "../../assets/images/arrow.svg";
import PrimaryButton from "../common/PrimaryButton";
import SecondaryButton from "../common/SecondaryButton";
import { SlArrowDown } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import figma from "../../assets/svgs/figmaSvg.svg";
import react from "../../assets/svgs/reactSvg.svg";
import { ellipseHero } from "../../assets/images";
import Nav from "../Nav";

const NewHero = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-[#151515]">
      <div className="flex bg-[#151515] flex-col mx-auto max-w-screen-2xl items-center justify-center">
        <Nav show className="bg-[#151515] text-white pt-6" />
        <div className="bg-[#151515] relative flex flex-col items-center w-full px-4 sm:px-8 h-[500px] sm:h-auto">
          <div className="absolute inset-0 w-full pointer-events-none">
            <img
              src={ellipseHero}
              alt="ellipse1"
              className="absolute z-10 -top-[20rem] left-[8rem]"
            />
            <img
              src={ellipseHero}
              alt="ellipse2"
              className="absolute -top-[15rem] -left-[3rem] z-10"
            />
            <img
              src={ellipseHero}
              alt="ellipse3"
              className="absolute z-10 -top-[5rem] -left-[10rem]"
            />
            <svg
              width="882"
              height="341"
              viewBox="0 0 882 341"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[100%] sm:-translate-y-[70%] hidden sm:block"
            >
              <path
                d="M881.5 170.5C881.5 193.853 869.26 216.156 847.007 236.497C824.753 256.839 792.523 275.181 752.654 290.595C672.919 321.422 562.733 340.5 441 340.5C319.267 340.5 209.081 321.422 129.346 290.595C89.4773 275.181 57.2474 256.839 34.9933 236.497C12.7395 216.156 0.5 193.853 0.5 170.5C0.5 147.147 12.7395 124.844 34.9933 104.503C57.2474 84.1608 89.4773 65.8188 129.346 50.4047C209.081 19.5776 319.267 0.5 441 0.5C562.733 0.5 672.919 19.5776 752.654 50.4047C792.523 65.8188 824.753 84.1608 847.007 104.503C869.26 124.844 881.5 147.147 881.5 170.5Z"
                stroke="url(#paint0_linear_1625_702)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1625_702"
                  x1="441"
                  y1="0"
                  x2="441"
                  y2="341"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F20B97" />
                  <stop offset="0.0739996" stop-color="#151515" />
                  <stop offset="0.829" stop-color="#151515" />
                  <stop offset="1" stop-color="#F20B97" />
                </linearGradient>
              </defs>
            </svg>
            <svg
              width="1002"
              height="423"
              viewBox="0 0 1002 423"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[90%] sm:-translate-y-[65%] hidden sm:block"
            >
              <path
                d="M1001.5 211.5C1001.5 240.53 987.565 268.23 962.276 293.471C936.984 318.715 900.361 341.471 855.066 360.592C764.478 398.835 639.297 422.5 501 422.5C362.703 422.5 237.522 398.835 146.934 360.592C101.639 341.471 65.016 318.715 39.7243 293.471C14.4345 268.23 0.5 240.53 0.5 211.5C0.5 182.47 14.4345 154.77 39.7243 129.529C65.016 104.285 101.639 81.5291 146.934 62.4076C237.522 24.1653 362.703 0.5 501 0.5C639.297 0.5 764.478 24.1653 855.066 62.4076C900.361 81.5291 936.984 104.285 962.276 129.529C987.565 154.77 1001.5 182.47 1001.5 211.5Z"
                stroke="url(#paint0_linear_1625_703)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1625_703"
                  x1="501"
                  y1="0"
                  x2="501"
                  y2="423"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#151515" />
                  <stop offset="0.144" stop-color="#151515" />
                  <stop offset="0.829" stop-color="#151515" />
                  <stop offset="1" stop-color="#F20B97" />
                </linearGradient>
              </defs>
            </svg>

            <div className="bg-[#151515] z-50 h-40 sm:h-80 w-40 rotate-45 sm:-rotate-45 blur-lg rounded-full absolute top-[60%] sm:top-[45%] left-[15rem] sm:left-[26rem] -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute z-40 left-80 top-[70%] sm:top-1/2 -translate-y-1/2 -translate-x-1/2">
              <svg
                width="217"
                height="217"
                viewBox="0 0 217 217"
                fill="none"
                className="w-[180px] sm:w-[217px] h-[180px] sm:h-[217px] animate-spin-slow"
              >
                <g opacity="0.25">
                  <circle
                    cx="108.5"
                    cy="108.5"
                    r="59"
                    stroke="white"
                    strokeDasharray="11 11"
                  />
                  <circle
                    cx="108.5"
                    cy="108.5"
                    r="86"
                    stroke="white"
                    strokeDasharray="11 11"
                  />
                  <circle
                    cx="108.5"
                    cy="108.5"
                    r="108"
                    stroke="white"
                    strokeDasharray="11 11"
                  />
                </g>
              </svg>
            </div>
            <div>
              <img
                src={figma}
                alt="figma-logo"
                className="absolute popup rounded-full z-50 icon-shadow left-[292px] sm:left-72 top-[64%] sm:top-[45%] -translate-x-1/2 -translate-y-1/2 size-14 sm:size-16"
              />
            </div>

            <div className="bg-[#151515] z-[9] h-60 w-32 sm:h-80 sm:w-40 -rotate-45 sm:rotate-45 blur-lg rounded-full absolute top-[60%] sm:top-[45%] right-32 sm:right-64 -translate-x-1/2 -translate-y-1/2"></div>
            <div className="absolute right-80 top-[70%] sm:top-1/2 -translate-y-1/2 translate-x-1/2">
              <svg
                width="217"
                height="217"
                viewBox="0 0 217 217"
                fill="none"
                className="w-[180px] sm:w-[217px] h-[180px] sm:h-[217px] animate-spin-slow-reverse"
              >
                <g opacity="0.25">
                  <circle
                    cx="108.5"
                    cy="108.5"
                    r="59"
                    stroke="white"
                    strokeDasharray="11 11"
                  />
                  <circle
                    cx="108.5"
                    cy="108.5"
                    r="86"
                    stroke="white"
                    strokeDasharray="11 11"
                  />
                  <circle
                    cx="108.5"
                    cy="108.5"
                    r="108"
                    stroke="white"
                    strokeDasharray="11 11"
                  />
                </g>
              </svg>
            </div>
            <img
              src={react}
              alt="react-logo"
              className="absolute popup z-40 rounded-full icon-shadow right-[292px] sm:right-72 top-[64%] sm:top-[45%] -translate-x-1/2 -translate-y-1/2 size-14 sm:size-16"
            />
          </div>
          <div className="flex flex-col gap-6 text-white py-10 sm:py-28 items-center max-w-4xl relative z-50">
            <div className="relative justify-items-center z-20">
              <h2 className="bricolage-grotesque-bold font-bold text-3xl sm:text-4xl xl:text-6xl">
                Marketing is no more
              </h2>
              <h2 className="mt-2 bricolage-grotesque-bold font-bold text-3xl sm:text-4xl xl:text-6xl">
                a risk you{" "}
                <span className="kaushan-script-regular">have to take</span>
              </h2>
            </div>
            <div className="mx-auto text-center max-w-xl z-10 text-[18px] opacity-50">
              We help brands and B2B companies build amazing websites that
              convert their visitors to paying customers.
            </div>

            <div onClick={() => navigate("/contact")}>
              <PrimaryButton
                className="relative z-20 bg-secondary"
                icon={<img src={arrow} alt="arrow" />}
              >
                Hire Us
              </PrimaryButton>
            </div>
          </div>
          <div className="w-full hidden sm:flex justify-end py-10 items-center gap-4">
            <div onClick={() => navigate("/contact")}>
              <SecondaryButton className="px-10 hover:bg-primary hover:text-white hover:border-transparent transition-all duration-300 font-montserrat relative z-10">
                Let's Talk
              </SecondaryButton>
            </div>
            <div className="flex flex-col relative z-10">
              <div
                className="p-2 bg-primary mx-auto text-white rounded-full cursor-pointer"
                onClick={() =>
                  window.scrollTo({
                    left: 0,
                    top: document.body.scrollHeight,
                    behavior: "smooth",
                  })
                }
              >
                <SlArrowDown />
              </div>

              <span className="text-[12px] mt-1 text-white">Scroll Down</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewHero;
