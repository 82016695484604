import React, { useEffect, useState } from "react";
import logo from "../assets/images/companylogo.svg";
import { SlArrowDown } from "react-icons/sl";
import { Link, useLocation } from "react-router-dom";
import SecondaryButton from "./common/SecondaryButton";
import { HashLink } from "react-router-hash-link";
import closeicon from "../assets/icons/closeicon.webp";

type LinkType = {
  name: string;
  path: string;
  href: string;
  lineHeight?: number;
  target?: string;
  options?: LinkType[];
  suboptions?: LinkType[];
  active?: boolean;
};

const links: LinkType[] = [
  {
    name: "Home",
    path: "/",
    href: "#",
    active: true,
  },
  {
    name: "Who we are",
    path: "/who-we-are",
    href: "",
    active: false,
  },
  {
    name: "What we do",
    href: "",
    path: "/what-we-do",
    active: false,
    options: [
      {
        name: "Design",
        path: "/design",
        lineHeight: 24,
        href: "/design",
        suboptions: [
          { name: "Logo Design", path: "", href: "/design#logo-design" },
          { name: "Brochuers & Flyers", path: "", href: "/design#broucher" },
          { name: "UI/UX Design", path: "", href: "/design#ui-ux-design" },
          { name: "Product Label Design", path: "", href: "/design#label" },
          {
            name: "Bussiness Card Design",
            path: "",
            href: "/design#bussiness-card",
          },
          { name: "Branding", path: "", href: "/design#branding" },
        ],
      },
      {
        name: "Web & App Development",
        href: "/web-app-development",
        path: "/web-app-development",
        suboptions: [
          {
            name: "Web Design",
            path: "",
            href: "/web-app-development#web-design",
          },
          {
            name: "App Design",
            path: "",
            href: "/web-app-development#app-design",
          },
        ],
      },
      {
        name: "Software Development",
        href: "/software-development",
        path: "/software-development",
        lineHeight: 200,
        suboptions: [
          { name: "Logo Design", path: "", href: "" },
          { name: "Brochuers & Flyers", path: "", href: "" },
          { name: "UI/UX Design", path: "", href: "" },
          { name: "Product Label Design", path: "", href: "" },
          { name: "Bussiness Card Design", path: "", href: "" },
          { name: "Branding", path: "", href: "" },
        ],
      },
      {
        name: "Marketing",
        href: "/marketing",
        path: "/marketing",
        lineHeight: 24,
        suboptions: [
          { name: "Logo Design", path: "", href: "" },
          { name: "Brochuers & Flyers", path: "", href: "" },
          { name: "UI/UX Design", path: "", href: "" },
          { name: "Product Label Design", path: "", href: "" },
          { name: "Bussiness Card Design", path: "", href: "" },
          { name: "Branding", path: "", href: "" },
        ],
      },
      {
        name: "Business Consultation",
        href: "/business-consultation",
        path: "/business-consultation",
        lineHeight: 24,
        suboptions: [
          { name: "Logo Design", path: "", href: "" },
          { name: "Brochuers & Flyers", path: "", href: "" },
          { name: "UI/UX Design", path: "", href: "" },
          { name: "Product Label Design", path: "", href: "" },
          { name: "Bussiness Card Design", path: "", href: "" },
          { name: "Branding", path: "", href: "" },
        ],
      },
    ],
  },
  {
    name: "Case Study",
    href: "",
    path: "/case-study",
  },
  {
    name: "Careers",
    href: "",
    path: "/careers",
  },
  {
    name: "Blog",
    href: "",
    path: "/blog",
  },
  {
    name: "Contact",
    href: "",
    path: "/contact",
  },
];

type Props = {
  show?: boolean;
  className?: string;
};

const Nav = ({ show, className }: Props) => {
  const [isOpen, setisOpen] = useState(false);
  const [activeOption, setActiveOption] = useState<string>("");
  const [navLinks, setNavLinks] = useState<LinkType[]>(links);
  const [isHome, setIsHome] = useState(true);

  const location = useLocation();

  const setActiveNav = (pageName: string) => {
    const newNavLinks = links.map((item) => {
      item.active = false;
      if (item.name === pageName) {
        item.active = true;
      }

      return item;
    });

    setNavLinks(newNavLinks);
  };

  const handleOptionClick = (linkName: string) => {
    setActiveOption((prev) => (prev === linkName ? "" : linkName)); // Use empty string instead of null
  };

  useEffect(() => {
    links.forEach((item) => {
      if (item.path === location.pathname) {
        setActiveNav(item.name);
      }
    });

    if (location.pathname === "/" && location.hash === "") {
      setActiveNav("Home");
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [location.pathname, location.hash]);

  const handleSchedule = () => {
    window.open("https://calendly.com/idiksha", "_blank");
  };

  return (
    <nav
      className={`${
        isHome && !show && "hidden"
      } py-2.5 w-screen relative ${className}`}
    >
      <div
        className="flex flex-col lg:flex-row flex-wrap self-end lg:items-center justify-between w-full
       px-5 sm:px-4 xl:px-8 max-w-screen-2xl mx-auto"
      >
        <Link to="/" className="relative z-20">
          {show ? (
            <WhiteLogo />
          ) : (
            <img
              src={logo}
              className="h-14 sm:h-20 relative z-10"
              alt="TechQilla"
            />
          )}
        </Link>

        <div className="flex items-center lg:order-2 justify-between mt-5 lg:mt-0">
          <SecondaryButton
            onClick={handleSchedule}
            className="text-sm text-black sm:text-[16px] hover:bg-primary hover:border-transparent hover:text-white font-montserrat transition-all duration-300 relative z-20"
          >
            Schedule a Call
          </SecondaryButton>
          <button
            onClick={() => setisOpen(!isOpen)}
            type="button"
            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="items-center font-montserrat lg:flex lg:w-auto lg:order-1"
          id="mobile-menu-2"
        >
          <ul
            className={`${
              isOpen ? "flex" : "hidden"
            } flex-col mt-4 font-medium lg:items-center lg:flex-row lg:flex lg:space-x-8 lg:mt-0 z-50`}
          >
            {navLinks.map((link, index) => (
              <li key={index}>
                {link.options ? (
                  <div className="w-full py-2 lg:py-0">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleOptionClick(link.name);
                      }}
                    >
                      <span className="text-base font-montserrat font-semibold">
                        {link.name}
                      </span>
                      <SlArrowDown className="ml-1" fontSize={10} />
                    </div>
                    {activeOption === link.name && (
                      <div
                        className="z-[999] absolute left-0 top-0 justify-center w-screen
                       h-screen bg-primary shadow-lg transition-all duration-500 items-center"
                      >
                        <div className="max-w-screen-2xl mx-auto py-8 px-5 lg:px-20">
                          <div className="flex justify-center">
                            <img
                              src={closeicon}
                              alt=""
                              className="p-4 rounded-full border border-white mb-4 cursor-pointer"
                              onClick={() => {
                                setActiveOption("");
                                setisOpen(false);
                              }}
                            />
                          </div>
                          <div className="flex flex-col sm:gap-10">
                            {link.options.map((option, optionIndex) => (
                              <div
                                key={optionIndex}
                                className="group  relative sm:flex justify-between items-start"
                              >
                                <div className="w-full">
                                  <div className="w-full ![&>div]:group-hover:flex flex-row items-center transition-all duration-500">
                                    <Link
                                      to={option.href}
                                      onClick={() => {
                                        setActiveOption("");
                                        setisOpen(false);
                                        setActiveNav("");
                                      }}
                                      className="block px-4 py-2 text-white font-montserrat text-xl lg:text-3xl font-bold"
                                    >
                                      {option.name}
                                    </Link>
                                  </div>
                                </div>
                                <div
                                  className={`hidden sm:block h-[1px] bg-white w-0 group-hover:w-full transition-all ease-in-out duration-500 origin-left mt-4`}
                                ></div>

                                {option.suboptions && (
                                  <ul
                                    className={`h-0 overflow-hidden transition-all duration-500 ease-in-out group-hover:h-auto group-hover:opacity-100 group-hover:visible group-hover:grid grid-cols-2 invisible opacity-0 bg-primary list-disc px-6 w-full`}
                                  >
                                    {option.suboptions.map(
                                      (suboption, subIndex) => (
                                        <li
                                          key={subIndex}
                                          className="list-disc marker:text-white ml-5"
                                        >
                                          <HashLink
                                            to={suboption.href}
                                            onClick={() => setActiveOption("")}
                                            className="block py-2 text-sm text-white"
                                          >
                                            {suboption.name}
                                          </HashLink>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="relative z-10 group text-start w-[100px] lg:w-auto mb-1 flex flex-col justify-center mt-2">
                    <Link
                      rel="noopener noreferrer"
                      to={link.path}
                      className="text-base bg-transparent z-10 text-start font-montserrat font-semibold"
                      onClick={() => {
                        setActiveNav(link.name);
                        setisOpen(false);
                      }}
                    >
                      {link.name}
                    </Link>
                    <hr
                      className={`h-1 rounded-full border-none ${
                        show ? "bg-secondary" : "bg-primary"
                      } transition-all duration-500 ease-in-out ${
                        link.active
                          ? "w-[50%] lg:w-[60%]"
                          : "w-0 group-hover:w-[50%] lg:group-hover:w-[50%]"
                      }`}
                    ></hr>
                    {/* <div
                      className={`absolute top-6 h-1 rounded-full ${
                        show ? "bg-secondary" : "bg-primary"
                      } transition-all duration-500 ease-in-out ${
                        link.active ? "w-[50%]" : "w-0 group-hover:w-[50%] "
                      }`}
                    ></div> */}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

const WhiteLogo = () => (
  <svg
    width="182"
    height="43"
    viewBox="0 0 182 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12.1743L34.8972 0L7.44185 24.7459L0 12.1743Z" fill="white" />
    <path
      d="M12.6074 24.7455L23.6618 12.3906L17.4482 42.9889L12.6074 24.7455Z"
      fill="white"
    />
    <path
      d="M17.4481 42.9889L16.7617 26.3351L23.6617 12.3906L17.4481 42.9889Z"
      fill="white"
    />
    <path
      d="M33.1988 24.0954L28.1051 8.27246L21.3496 38.7985L33.1988 24.0954Z"
      fill="white"
    />
    <path
      d="M21.3496 38.7985L28.7553 23.8425L28.1051 8.27246L21.3496 38.7985Z"
      fill="white"
    />
    <path
      d="M7.44141 24.7459L8.27229 16.1481L34.8968 0L7.44141 24.7459Z"
      fill="white"
    />
    <path
      d="M27.4907 34.5713L33.343 42.0493L25.0703 37.4252L27.4907 34.5713Z"
      fill="white"
    />
    <path
      d="M25.0703 37.4257L27.2378 36.9199L33.343 42.0497L25.0703 37.4257Z"
      fill="white"
    />
    <path
      d="M56.5364 9.17578V11.8852H51.3343V26.9134H48.4081V11.8852H43.2422V9.17578H56.5364Z"
      fill="white"
    />
    <path
      d="M62.2445 24.1678H69.9031V26.9134H59.3184V9.17578H69.6141V11.9213H62.2445V16.5454H68.9639V19.2187H62.2445V24.1678Z"
      fill="white"
    />
    <path
      d="M81.067 27.2389C79.8387 27.2389 78.5743 27.0221 77.4183 26.5525C76.3346 26.119 75.3592 25.4688 74.5283 24.6379C73.6974 23.807 73.0833 22.7955 72.6498 21.7117C71.7105 19.3635 71.7105 16.7264 72.6498 14.3782C73.0833 13.2945 73.7335 12.283 74.5283 11.4521C75.3592 10.6212 76.3346 9.97093 77.4183 9.53742C78.5743 9.06779 79.8026 8.85104 81.067 8.85104C83.0539 8.81491 84.9685 9.46517 86.5219 10.6573C88.0753 11.8495 89.123 13.5473 89.5565 15.462H86.6303C86.2329 14.3421 85.5104 13.3667 84.535 12.6803C82.0424 11.0908 78.7911 11.4159 76.6958 13.4751C75.5398 14.7034 74.8895 16.3651 74.9618 18.063C74.8895 19.7609 75.5398 21.3866 76.6958 22.6148C77.8518 23.807 79.4413 24.4572 81.1031 24.4211C82.3675 24.4572 83.5958 24.0599 84.6073 23.3373C85.6188 22.6148 86.3413 21.5672 86.7026 20.3751H89.6649C88.834 24.4572 85.2214 27.3473 81.067 27.2389Z"
      fill="white"
    />
    <path
      d="M103.716 9.17578H106.642V26.9134H103.716V19.1825H95.6234V26.9134H92.6973V9.17578H95.6234V16.437H103.716V9.17578Z"
      fill="white"
    />
    <path
      d="M129.438 28.8284H125.536L123.441 26.4442C120.948 27.5279 118.094 27.6002 115.529 26.5887C114.409 26.1552 113.398 25.5049 112.531 24.6379C111.664 23.807 111.014 22.7955 110.544 21.6756C109.569 19.3274 109.569 16.6903 110.544 14.3421C111.014 13.2222 111.664 12.2468 112.531 11.416C113.398 10.5851 114.409 9.93482 115.529 9.46519C117.95 8.52593 120.623 8.52593 123.043 9.46519C125.283 10.3322 127.09 12.1023 127.993 14.3421C128.968 16.7264 128.932 19.3636 127.993 21.7478C127.523 22.8677 126.801 23.8792 125.933 24.7463L129.438 28.8284ZM119.323 23.6986C119.864 23.6986 120.406 23.6264 120.912 23.4819L117.372 19.3997H121.273L123.441 21.8923C125.392 19.6164 125.319 16.2207 123.26 14.0531C121.057 11.8856 117.552 11.8856 115.349 14.0531C113.217 16.2929 113.217 19.8332 115.349 22.073C116.396 23.1206 117.841 23.6986 119.323 23.6986Z"
      fill="white"
    />
    <path d="M135.614 9.17578H131.785V26.9134H135.614V9.17578Z" fill="white" />
    <path
      d="M143.489 23.3731H150.317V26.9134H139.66V9.17578H143.489V23.3731Z"
      fill="white"
    />
    <path
      d="M156.712 23.3731H163.54V26.9134H152.883V9.17578H156.712V23.3731Z"
      fill="white"
    />
    <path
      d="M177.882 26.9134L176.654 23.5537H169.609L168.381 26.9134H164.299L171.235 9.17578H174.847L182 26.9134H177.882ZM173.113 13.8721L170.801 20.2302H175.462L173.113 13.8721Z"
      fill="white"
    />
    <path
      d="M50.4663 33.3082C50.5747 33.4527 50.647 33.6333 50.647 33.814C50.647 34.1391 50.4663 34.4642 50.1412 34.5726C49.9606 34.6448 49.7438 34.681 49.5632 34.681H48.1543V31.3936H49.4909C49.6716 31.3936 49.8883 31.4297 50.0689 31.5019C50.2134 31.5742 50.3579 31.6826 50.4302 31.7909C50.5024 31.9354 50.5386 32.0799 50.5386 32.2244C50.5386 32.4051 50.5025 32.5496 50.3941 32.6941C50.2857 32.8386 50.1412 32.9108 49.9967 32.9831C50.1773 33.0553 50.3579 33.1637 50.4663 33.3082ZM48.8046 32.8024H49.4187C49.5632 32.8024 49.6716 32.7663 49.7799 32.6941C49.8522 32.6218 49.9244 32.5134 49.9244 32.4051C49.9244 32.2967 49.8883 32.1883 49.7799 32.1161C49.6716 32.0438 49.5632 32.0077 49.4187 32.0077H48.8046V32.8024ZM49.8522 34.0668C49.9606 33.9946 49.9967 33.8862 49.9967 33.7417C49.9967 33.5972 49.9606 33.4888 49.8522 33.4166C49.7438 33.3443 49.5993 33.2721 49.4548 33.3082H48.8046V34.1752H49.4548C49.5993 34.2113 49.7438 34.1391 49.8522 34.0668Z"
      fill="white"
    />
    <path
      d="M55.1627 34.7161L54.4402 33.4517H54.1151V34.7161H53.4648V31.4287H54.6931C54.9099 31.4287 55.1266 31.4648 55.3434 31.5732C55.524 31.6455 55.6324 31.79 55.7407 31.9345C56.0298 32.4041 55.8491 32.9821 55.4156 33.2711C55.3434 33.3072 55.235 33.3434 55.1266 33.3795L55.9214 34.7161H55.1627ZM54.1151 32.946H54.657C54.8015 32.946 54.946 32.9099 55.0544 32.8015C55.1627 32.6931 55.1989 32.5847 55.1989 32.4402C55.1989 32.2957 55.1627 32.1873 55.0544 32.079C54.946 31.9706 54.8015 31.9345 54.657 31.9706H54.1151V32.946Z"
      fill="white"
    />
    <path
      d="M60.7994 34.102H59.4988L59.2821 34.7161H58.5957L59.7878 31.4287H60.5465L61.7386 34.7161H61.0522L60.7994 34.102ZM60.6187 33.5601L60.1491 32.1873L59.6795 33.5601H60.6187Z"
      fill="white"
    />
    <path
      d="M67.266 34.7161H66.6158L65.1346 32.4764V34.7161H64.4844V31.4287H65.1346L66.6158 33.6685V31.4287H67.266V34.7161Z"
      fill="white"
    />
    <path
      d="M72.2877 31.6459C72.5405 31.7904 72.7573 31.971 72.9018 32.2239C73.0463 32.4768 73.1186 32.8019 73.1186 33.0909C73.1186 33.3799 73.0463 33.705 72.9018 33.9579C72.7573 34.2108 72.5405 34.3914 72.2877 34.5359C71.9987 34.6804 71.7097 34.7527 71.3845 34.7527H70.2285V31.4653H71.3845C71.6735 31.4291 71.9987 31.5014 72.2877 31.6459ZM72.1432 33.8857C72.3238 33.6689 72.4322 33.3799 72.4322 33.0909C72.4322 32.8019 72.3599 32.5129 72.1432 32.2961C71.9264 32.0794 71.6374 32.0071 71.3484 32.0071H70.8788V34.1747H71.3484C71.6374 34.1747 71.9264 34.0663 72.1432 33.8857Z"
      fill="white"
    />
    <path d="M76.5507 31.4287V34.7161H75.9004V31.4287H76.5507Z" fill="white" />
    <path
      d="M82.2934 34.7161H81.6431L80.162 32.4764V34.7161H79.5117V31.4287H80.162L81.6431 33.6685V31.4287H82.2934V34.7161Z"
      fill="white"
    />
    <path
      d="M87.5327 32.4402C87.4605 32.2957 87.3521 32.1873 87.2076 32.1151C87.0631 32.0428 86.9186 32.0067 86.7379 32.0067C86.5573 32.0067 86.3767 32.0428 86.2322 32.1512C86.0877 32.2235 85.9432 32.368 85.8709 32.5125C85.7987 32.6931 85.7264 32.8737 85.7264 33.0905C85.7264 33.3072 85.7626 33.4879 85.8709 33.6685C85.9432 33.813 86.0877 33.9575 86.2322 34.0297C86.4128 34.102 86.5934 34.1742 86.7741 34.1742C86.9908 34.1742 87.2076 34.102 87.3882 33.9575C87.5688 33.813 87.6772 33.6324 87.7133 33.4156H86.5934V32.9099H88.3636V33.4879C88.3275 33.7046 88.2191 33.9214 88.0746 34.1381C87.9301 34.3187 87.7495 34.4994 87.5327 34.6078C87.2798 34.7161 87.0269 34.7884 86.7741 34.7884C86.4851 34.7884 86.1961 34.7161 85.9071 34.5716C85.6542 34.4271 85.4374 34.2465 85.2929 33.9936C85.0039 33.4517 85.0039 32.8015 85.2929 32.2596C85.4374 32.0067 85.6542 31.79 85.9071 31.6455C86.1599 31.501 86.4489 31.4287 86.7741 31.4287C87.0992 31.4287 87.4605 31.501 87.7495 31.7177C88.0385 31.8983 88.2191 32.1512 88.3275 32.4764L87.5327 32.4402Z"
      fill="white"
    />
    <path
      d="M96.8882 33.3082C96.9966 33.4527 97.0688 33.6333 97.0688 33.814C97.0688 34.1391 96.8882 34.4642 96.5631 34.5726C96.3824 34.6448 96.1657 34.681 95.9851 34.681H94.5762V31.3936H95.9128C96.0934 31.3936 96.3102 31.4297 96.4908 31.5019C96.6353 31.5742 96.7798 31.6826 96.8521 31.7909C96.9243 31.9354 96.9605 32.0799 96.9605 32.2244C96.9605 32.4051 96.9243 32.5496 96.8159 32.6941C96.7076 32.8386 96.5631 32.9108 96.4186 32.9831C96.5992 33.0553 96.7798 33.1637 96.8882 33.3082ZM95.2264 32.8024H95.8406C95.9851 32.8024 96.0934 32.7663 96.2018 32.6941C96.2741 32.6218 96.3463 32.5134 96.3463 32.4051C96.3463 32.2967 96.3102 32.1883 96.2018 32.1161C96.0934 32.0438 95.9851 32.0077 95.8406 32.0077H95.2264V32.8024ZM96.2741 34.0668C96.3463 33.9946 96.4186 33.8862 96.4186 33.7417C96.4186 33.5972 96.3824 33.4888 96.2741 33.4166C96.1657 33.3443 96.0212 33.2721 95.8767 33.3082H95.2264V34.1752H95.8767C96.0212 34.1752 96.1657 34.1391 96.2741 34.0668Z"
      fill="white"
    />
    <path
      d="M100.538 31.9706V32.8015H101.622V33.3072H100.538V34.1742H101.766V34.7161H99.8516V31.4287H101.766V31.9706H100.538Z"
      fill="white"
    />
    <path
      d="M107.292 31.4287L106.173 33.5601V34.7161H105.522V33.5962L104.402 31.4648H105.125L105.847 32.946L106.534 31.4648L107.292 31.4287Z"
      fill="white"
    />
    <path
      d="M110.653 34.5355C110.4 34.391 110.184 34.1742 110.039 33.9214C109.895 33.6685 109.822 33.3433 109.822 33.0543C109.822 32.7653 109.895 32.4402 110.039 32.1873C110.184 31.9345 110.4 31.7177 110.653 31.5732C110.906 31.4287 111.195 31.3564 111.52 31.3564C111.809 31.3564 112.098 31.4287 112.351 31.5732C112.604 31.7177 112.821 31.9345 112.965 32.1873C113.11 32.4402 113.182 32.7292 113.182 33.0543C113.182 33.3433 113.11 33.6685 112.965 33.9214C112.821 34.1742 112.604 34.391 112.351 34.5355C111.809 34.8245 111.195 34.8245 110.653 34.5355ZM112.026 34.0297C112.17 33.9575 112.315 33.813 112.387 33.6324C112.459 33.4517 112.532 33.2711 112.532 33.0543C112.532 32.8376 112.496 32.657 112.387 32.4763C112.315 32.3318 112.17 32.1873 112.026 32.1151C111.701 31.9345 111.303 31.9345 110.978 32.1151C110.834 32.1873 110.689 32.3318 110.617 32.4763C110.545 32.657 110.473 32.8376 110.473 33.0543C110.473 33.2711 110.509 33.4517 110.617 33.6324C110.689 33.7769 110.834 33.9214 110.978 34.0297C111.303 34.2104 111.701 34.2104 112.026 34.0297Z"
      fill="white"
    />
    <path
      d="M118.782 34.7161H118.131L116.65 32.4764V34.7161H116V31.4287H116.65L118.131 33.6685V31.4287H118.782V34.7161Z"
      fill="white"
    />
    <path
      d="M123.766 31.6459C124.019 31.7904 124.236 31.971 124.38 32.2239C124.525 32.4768 124.597 32.8019 124.597 33.0909C124.597 33.3799 124.525 33.705 124.38 33.9579C124.236 34.2108 124.019 34.3914 123.766 34.5359C123.477 34.6804 123.188 34.7527 122.863 34.7527H121.707V31.4653H122.863C123.188 31.4291 123.477 31.5014 123.766 31.6459ZM123.658 33.8857C123.838 33.6689 123.947 33.3799 123.947 33.0909C123.947 32.8019 123.875 32.5129 123.658 32.2961C123.441 32.0794 123.152 32.0071 122.863 32.0071H122.393V34.1747H122.863C123.152 34.1747 123.405 34.0663 123.658 33.8857Z"
      fill="white"
    />
    <path
      d="M133.123 33.3072C133.231 33.4517 133.303 33.6324 133.303 33.813C133.303 33.9936 133.267 34.1381 133.159 34.2826C133.086 34.4271 132.942 34.5355 132.797 34.6078C132.617 34.68 132.4 34.7161 132.219 34.7161H130.811V31.4287H132.147C132.328 31.4287 132.545 31.4648 132.725 31.5371C132.87 31.6093 133.014 31.7177 133.086 31.8261C133.159 31.9706 133.195 32.1151 133.195 32.2596C133.195 32.4402 133.159 32.5847 133.05 32.7292C132.942 32.8737 132.797 32.946 132.653 33.0182C132.834 33.0544 133.014 33.1627 133.123 33.3072ZM131.461 32.8015H132.075C132.219 32.8015 132.328 32.7654 132.436 32.6931C132.508 32.6209 132.581 32.5125 132.581 32.4041C132.581 32.2957 132.545 32.1873 132.436 32.1151C132.328 32.0428 132.219 32.0067 132.075 32.0067H131.461V32.8015ZM132.508 34.0659C132.617 33.9936 132.653 33.8852 132.653 33.7407C132.653 33.5962 132.617 33.4879 132.508 33.4156C132.4 33.3434 132.256 33.2711 132.147 33.3072H131.497V34.1742H132.147C132.256 34.2104 132.4 34.1381 132.508 34.0659Z"
      fill="white"
    />
    <path
      d="M136.807 34.5355C136.555 34.391 136.338 34.1742 136.193 33.9214C136.049 33.6685 135.977 33.3433 135.977 33.0543C135.977 32.7653 136.049 32.4402 136.193 32.1873C136.338 31.9345 136.555 31.7177 136.807 31.5732C137.06 31.4287 137.349 31.3564 137.638 31.3564C137.927 31.3564 138.216 31.4287 138.469 31.5732C138.722 31.7177 138.939 31.9345 139.083 32.1873C139.228 32.4402 139.3 32.7292 139.3 33.0543C139.3 33.3433 139.228 33.6685 139.083 33.9214C138.939 34.1742 138.722 34.391 138.469 34.5355C137.963 34.8245 137.349 34.8245 136.807 34.5355ZM138.18 34.0297C138.325 33.9575 138.469 33.813 138.541 33.6324C138.614 33.4517 138.686 33.2711 138.686 33.0543C138.686 32.8376 138.65 32.657 138.541 32.4763C138.469 32.3318 138.325 32.1873 138.18 32.1151C137.855 31.9345 137.458 31.9345 137.133 32.1151C136.988 32.1873 136.844 32.3318 136.771 32.4763C136.699 32.657 136.627 32.8376 136.627 33.0543C136.627 33.2711 136.663 33.4517 136.771 33.6324C136.844 33.7769 136.988 33.9214 137.133 34.0297C137.458 34.2104 137.855 34.2104 138.18 34.0297Z"
      fill="white"
    />
    <path
      d="M142.767 31.4287V33.4517C142.767 33.6324 142.804 33.8491 142.948 33.9936C143.237 34.2104 143.634 34.2104 143.923 33.9936C144.032 33.8491 144.104 33.6685 144.104 33.4879V31.4648H144.754V33.4879C144.754 33.7407 144.718 33.9936 144.574 34.2104C144.465 34.391 144.285 34.5355 144.104 34.6439C143.887 34.7523 143.671 34.7884 143.418 34.7884C143.201 34.7884 142.948 34.7523 142.767 34.6439C142.587 34.5355 142.406 34.391 142.298 34.2104C142.189 33.9936 142.117 33.7407 142.117 33.4879V31.4648L142.767 31.4287Z"
      fill="white"
    />
    <path
      d="M150.498 34.7161H149.848L148.367 32.4764V34.7161H147.717V31.4287H148.367L149.848 33.6685V31.4287H150.498V34.7161Z"
      fill="white"
    />
    <path
      d="M155.485 31.6459C155.738 31.7543 155.955 31.971 156.099 32.2239C156.244 32.4768 156.316 32.8019 156.316 33.0909C156.316 33.3799 156.244 33.705 156.099 33.9579C155.955 34.2108 155.738 34.3914 155.485 34.5359C155.196 34.6804 154.907 34.7527 154.582 34.7527H153.426V31.4653H154.582C154.907 31.4291 155.232 31.5014 155.485 31.6459ZM155.377 33.8857C155.557 33.6689 155.666 33.3799 155.666 33.0909C155.666 32.8019 155.593 32.5129 155.377 32.2961C155.16 32.0794 154.871 32.0071 154.582 32.0071H154.112V34.1747H154.582C154.871 34.1747 155.16 34.0663 155.377 33.8857Z"
      fill="white"
    />
    <path
      d="M161.12 34.102H159.819L159.602 34.7161H158.916L160.108 31.4287H160.867L162.059 34.7161H161.373L161.12 34.102ZM160.939 33.5601L160.469 32.1873L160 33.5601H160.939Z"
      fill="white"
    />
    <path
      d="M166.465 34.7161L165.743 33.4517H165.418V34.7161H164.768V31.4287H165.996C166.213 31.4287 166.429 31.4648 166.646 31.5732C166.827 31.6455 166.971 31.79 167.043 31.9345C167.116 32.079 167.188 32.2596 167.188 32.4402C167.188 32.9099 166.899 33.3072 166.429 33.3795L167.224 34.7161H166.465ZM165.418 32.946H165.96C166.104 32.946 166.249 32.9099 166.357 32.8015C166.465 32.6931 166.502 32.5847 166.502 32.4402C166.502 32.2957 166.465 32.1873 166.357 32.079C166.249 31.9706 166.104 31.9345 165.96 31.9706H165.418V32.946Z"
      fill="white"
    />
    <path d="M170.728 31.4287V34.7161H170.078V31.4287H170.728Z" fill="white" />
    <path
      d="M174.342 31.9706V32.8015H175.462V33.3072H174.342V34.1742H175.606V34.7161H173.691V31.4287H175.606V31.9706H174.342Z"
      fill="white"
    />
    <path
      d="M179.001 34.6439C178.82 34.5716 178.676 34.4633 178.567 34.3187C178.459 34.1742 178.387 33.9936 178.387 33.813H179.109C179.109 33.9214 179.181 34.0659 179.254 34.1381C179.362 34.2104 179.47 34.2465 179.615 34.2465C179.759 34.2465 179.868 34.2104 179.976 34.1381C180.121 34.0297 180.157 33.7769 180.049 33.6324C180.049 33.6324 180.049 33.6324 180.049 33.5962C179.976 33.524 179.904 33.4879 179.832 33.4517C179.723 33.4156 179.579 33.3795 179.47 33.3434C179.29 33.3072 179.109 33.235 178.965 33.1627C178.82 33.0905 178.712 33.0182 178.603 32.8737C178.495 32.7292 178.459 32.5486 178.459 32.368C178.459 32.1873 178.495 32.0067 178.603 31.8622C178.712 31.7177 178.856 31.6093 179.001 31.5371C179.181 31.4648 179.398 31.4287 179.615 31.4287C179.904 31.4287 180.193 31.501 180.446 31.6816C180.663 31.8622 180.807 32.1151 180.807 32.368H180.085C180.085 32.2596 180.012 32.1512 179.94 32.079C179.832 32.0067 179.723 31.9706 179.579 31.9706C179.471 31.9706 179.362 32.0067 179.29 32.079C179.218 32.1512 179.181 32.2596 179.181 32.368C179.181 32.4402 179.218 32.5125 179.254 32.5847C179.326 32.657 179.398 32.6931 179.47 32.7292C179.543 32.7654 179.687 32.8015 179.832 32.8376C180.012 32.8737 180.193 32.946 180.374 33.0182C180.518 33.0905 180.626 33.1627 180.735 33.3072C180.843 33.4517 180.879 33.6324 180.879 33.813C180.879 33.9936 180.843 34.1381 180.735 34.3187C180.626 34.4633 180.482 34.6078 180.338 34.68C180.157 34.7884 179.94 34.8245 179.723 34.8245C179.434 34.7523 179.218 34.7161 179.001 34.6439Z"
      fill="white"
    />
  </svg>
);

export default Nav;
