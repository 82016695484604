import React, { useState } from "react";
import PrimaryButton from "../common/PrimaryButton";
import arrow from "../../assets/images/arrow.svg";
import joinUsImg1 from "../../assets/images/joinUsimg1 - Copy.webp";
import joinUsImg2 from "../../assets/images/joinUsimg2 - Copy.webp";
import joinUsImg3 from "../../assets/images/homejoin2.webp";
import logo from "../../assets/svgs/techqillalogo.svg";
import { useNavigate } from "react-router-dom";

const JoinUs = () => {
  const [hoveredImage, setHoveredImage] = useState(null);

  const navigate = useNavigate();

  return (
    <section className="relative w-full flex justify-center items-center  bg-primary">
      <div className="w-full px-5 sm:px-20 py-8 sm:py-28 max-w-screen-2xl mx-auto">
        <div className="flex justify-between flex-col sm:flex-row">
          <div className="p-10 sm:p-0">
            <div className="p-10 sm:p-20 size-[300px] flex justify-center items-center sm:size-auto border border-[#F20B97] rounded-full relative positive-animate-spin1">
              <div className="size-14 sm:size-28 absolute -left-5 sm:-left-10 bottom-24 rounded-full overflow-hidden -animate-spin1">
                <img
                  src={joinUsImg1}
                  onMouseEnter={() => setHoveredImage(joinUsImg1)}
                  alt=""
                  className="object-cover object-center border-4 border-white rounded-full size-14 sm:size-28"
                />
              </div>
              <div className="size-14 sm:size-28 absolute -right-5 sm:-right-10 bottom-32 rounded-full overflow-hidden -animate-spin1">
                <img
                  src={joinUsImg2}
                  onMouseEnter={() => setHoveredImage(joinUsImg2)}
                  alt=""
                  className="object-cover object-center border-4 border-white rounded-full  size-14 sm:size-28"
                />
              </div>
              <div className="p-10 sm:p-20 size-[200px] sm:size-auto flex justify-center items-center border border-[#F20B97] rounded-full relative">
                <div className="size-14 sm:size-28 absolute -top-5 sm:-top-10 rounded-full overflow-hidden  -animate-spin1">
                  <img
                    src={joinUsImg3}
                    onMouseEnter={() => setHoveredImage(joinUsImg3)}
                    alt=""
                    className="object-cover size-14 sm:size-28 object-center -animate-spin border-4 border-white rounded-full"
                  />
                </div>
                <div className="size-24 sm:size-44 bg-white rounded-full flex justify-center items-center relative z-10 -animate-spin">
                  <img
                    src={logo}
                    alt=""
                    className="w-[50px] sm:w-[100px] -animate-spin1"
                  />
                </div>
              </div>
            </div>
            {hoveredImage && (
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div
                  onMouseLeave={() => setHoveredImage(null)}
                  className="bg-white p-5 rounded-lg shadow-lg relative"
                >
                  <button
                    className="absolute top-2 right-2 text-xl font-bold text-gray-700"
                    onClick={() => setHoveredImage(null)}
                  >
                    &times;
                  </button>
                  <img
                    src={hoveredImage}
                    alt=""
                    className=" sm:w-[700px] sm:h-[500px] rounded-lg object-cover"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="max-w-lg sm:my-20">
            <h2 className="text-3xl font-extrabold font-montserrat text-white mb-4 sm:mb-8 sm:text-5xl">
              Want to join our team?
            </h2>
            <p className="mt-4 text-white text-lg">
              Ready to make an impact? Join our team at Techqilla! We're looking
              for passionate individuals to drive innovation and growth. Let’s
              build the future together!
            </p>
            <PrimaryButton
              onClick={() => {
                navigate("/careers");
              }}
              className=" mt-10 relative z-10 bg-primary border border-white"
              icon={<img src={arrow} alt="arrow" className="text-black" />}
            >
              View current openings
            </PrimaryButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinUs;
